import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"

import "../form/form.css"

const Subscription = () => {
  const [allowSubmit, setSubmit] = useState(false)
  const [formData, updateFormData] = useState({
    email: "",
  })
  const [formStatus, setFormStatus] = useState("")

  const handleEmailInput = email => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (emailRegex.test(email)) {
      setSubmit(true)
      updateFormData({
        ...formData,
        email,
      })
    } else {
      setSubmit(false)
    }
  }

  const submitForm = form => {
    const data = new FormData()
    data.append("email", formData.email)
    data.append("form-name", "subscribe")

    setSubmit(false)

    fetch("/", {
      method: "POST",
      body: data,
    })
      .then(res => {
        setSubmit(true)

        if (res.ok) {
          setFormStatus("SUBMITTED")
        } else {
          setFormStatus("FAILED")
        }
      })
      .catch(error => {
        setSubmit(true)
        setFormStatus("FAILED")
      })
  }

  if (formStatus === "SUBMITTED") {
    return (
      <div id="form-box">
        You are subscribed{" "}
        <span aria-label="double-high-fives" role="img">
          🙌
        </span>
      </div>
    )
  }

  return (
    <div id="form-box">
      <p>
        Hello{" "}
        <span aria-label="hand-wave" role="img">
          👋
        </span>
        <br />
        Subscribe for wholesome stuff about life, tech, football, physics and
        everything else.
        <br />
        No spam, and there's unsubscribe link in every message.
      </p>
      <form
        name="subscribe"
        method="POST"
        data-netlify="true"
        onSubmit={e => {
          e.preventDefault()
          submitForm()
        }}
      >
        <Container>
          <Row sm={1}>
            <Col>
              <input
                type="email"
                name="email"
                placeholder="awesome.person@email.com"
                onChange={e => {
                  handleEmailInput(e.target.value)
                }}
              />
            </Col>
            <Col>
              <button type="submit" disabled={!allowSubmit}>
                Subscribe
              </button>
            </Col>
          </Row>
        </Container>
      </form>
      {formStatus === "FAILED" ? (
        <div>
          {" "}
          Something went wrong! Please try again!{" "}
          <span aria-label="disappointed" role="img">
            😞
          </span>{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default Subscription
