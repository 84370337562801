import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Subscription from "../components/subscription/subscription"

import "../pages-styles/index.css"
class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const aboutYaml = data.pagesYaml

    return (
      <Layout location={this.props.location}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Container>
          <Row md={1} lg={2} className="grid-elem-centering" id="brief-info">
            <Col md className="grid-elem-centering">
              <img
                id="profile-image"
                src={aboutYaml.photo.childImageSharp.resolutions.src}
                alt="Profile"
              />
            </Col>
            <Col md className="grid-elem-centering">
              <h2 id="index-greetings">{aboutYaml.aboutMe}</h2>
            </Col>
          </Row>
        </Container>
        <Subscription />
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    pagesYaml {
      name
      nickname
      quote
      aboutMe
      photo {
        childImageSharp {
          resolutions {
            src
          }
        }
      }
    }
  }
`
